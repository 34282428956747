<template>

    <div class="mb-3 card" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">

        <div>

            <v-container fluid>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerName"
                                      :label="$t('erp.lang_ware_producer_name')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerCustID"
                                      :label="$t('customers.lang_customerID')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerStreet"
                                      :label="$t('generic.lang_street')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerStreetNo"
                                      :label="$t('localbee.lang_lbRestaurantStreetNo')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerZip"
                                      :label="$t('customers.lang_cust_zip')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerCity"
                                      :label="$t('settings.lang_city')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerCountry"
                                      :label="$t('generic.lang_country')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerContactPerson"
                                      :label="$t('generic.lang_ansprechpartner')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerContactPersonPhone"
                                      :label="$t('erp.lang_extensionContactPerson')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerPhone"
                                      :label="$t('generic.lang_telefone')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerFax"
                                      :label="$t('generic.lang_fax')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>

                </v-layout>
                <v-layout wrap>

                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      type="email"
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producereMail"
                                      :label="$t('generic.lang_emailAdresse')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="producerWebsite"
                                      :label="$t('generic.lang_Website')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>

                    <v-flex md12 xs12>
                        <v-textarea outlined
                                    type="email"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="producerNotes"
                                    :label="$t('generic.lang_internalNotes')"
                                    autocomplete="off"
                        >
                        </v-textarea>
                    </v-flex>
                </v-layout>

                <v-layout>
                    <v-flex style="text-align:right;">
                        <v-btn text color="error" @click="goBackToProducers">{{ $t('generic.lang_prev') }}</v-btn>
                        <v-btn color="error" v-if="this.$store.getters['permissions/checkPermission']('manufacturer_delete')" :disabled="(!id || parseInt(id) < 1)" @click="deleteData">{{
                            $t('generic.lang_delete') }}
                        </v-btn>
                        <v-btn color="success" :disabled="producerName.length < 1" @click="editData">{{
                            $t('generic.lang_edit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>

        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {},
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                producerName: "",
                producerCustID: "",
                producerStreet: "",
                producerStreetNo: "",
                producerZip: "",
                producerCity: "",
                producerCountry: "",
                producerContactPerson: "",
                producerContactPersonPhone: "",
                producerPhone: "",
                producerFax: "",
                producereMail: "",
                producerWebsite: "",
                producerNotes: "",
            }
        },


        methods: {

            /* DELETE A SINGLE PRODUCER OR MULTIPLE PRODUCERS
    * Awaits Object of producer IDs
    * */

            deleteData: function (idsToDelete = []) {
                this.$swal({
                    title: this.$t("erp.deleteProducerHeader"),
                    text: this.$t("erp.deleteProducerBody"),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.PRODUCERS.DELETE, {
                            producerID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.producerDeleted"),
                                    color: "success"
                                });

                                this.goBackToProducers();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            /*GO BACK TO producer LIST */
            goBackToProducers: function () {
                this.$router.push('/erp/storage/producer');
            },
            /*EDIT producer */
            editData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.PRODUCERS.UPDATE, {
                    producerID: this.id,
                    producerName: this.producerName,
                    producerCustNo: this.producerCustID,
                    producerStreetNo: this.producerStreetNo,
                    producerStreet: this.producerStreet,
                    producerZip: this.producerZip,
                    producerCity: this.producerCity,
                    producerCountry: this.producerCountry,
                    producerContactPerson: this.producerContactPerson,
                    producerContactPersonPhone: this.producerContactPersonPhone,
                    producerPhone: this.producerPhone,
                    producerFax: this.producerFax,
                    producerEMail: this.producereMail,
                    producerWebsite: this.producerWebsite,
                    producerNotes: this.producerNotes,

                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message:  this.$t('erp.producerUpdated'),
                            color: "success"
                        });
                        this.goBackToProducers();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },


            getData: function () {
                this.loading = true;

                this.id = parseInt(this.$parent.id);
                this.axios.post(ENDPOINTS.ERP.PRODUCERS.GET, {
                    producerID: this.id
                }).then((res) => {
                    if (res.status === 200) {
                        this.loading = false;


                        this.producerName = res.data.formfillData.textFields.producerName;
                        this.producerCustID = res.data.formfillData.textFields.producerCustNo;
                        this.producerStreet = res.data.formfillData.textFields.producerStreet;
                        this.producerStreetNo = res.data.formfillData.textFields.producerStreetNo;
                        this.producerZip = res.data.formfillData.textFields.producerZip;
                        this.producerCity = res.data.formfillData.textFields.producerCity;
                        this.producerCountry = res.data.formfillData.textFields.producerCountry;
                        this.producerContactPerson = res.data.formfillData.textFields.producerContactPerson;
                        this.producerContactPersonPhone = res.data.formfillData.textFields.producerContactPersonPhone;
                        this.producerPhone = res.data.formfillData.textFields.producerPhone;
                        this.producerFax = res.data.formfillData.textFields.producerFax;
                        this.producereMail = res.data.formfillData.textFields.producerEMail;
                        this.producerWebsite = res.data.formfillData.textFields.producerWebsite;
                        this.producerNotes = res.data.formfillData.textFields.producerNotes;
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
        },
        mounted() {
            this.getData();
        },
    }
</script>